<template>
  <div>
    <div class="Top_date">
      <div class="Top_title">搜索条件</div>
      <div class="Top_conter">
        <el-select v-model="Rangedate" placeholder="请选择时间范围" @change="RangedateChange" clearable>
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <div class="block">
          <!--          <span class="demonstration">默认</span>-->
          <el-date-picker
              v-model="customdate" @change="customdateChange"
              type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>

    </div>
    <div class="Top_Total">
      <div class="Top_TotalL">
        <div class="Top_title">付款金额统计</div>

        <div class="Top_TotalLLi">{{draw1Price}}</div>
      </div>
      <div class="Top_TotalR">
        <div class="Top_title">采购金额统计</div>
        <div class="Top_TotalRLi">{{drawprice}}</div>

      </div>
    </div>
    <el-card>
      <div class="title">付款金额</div>
      <div id="myChart" :style="{width: '100%', height: '300px'}"></div>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div class="title">采购金额</div>
      <div id="myChartdrawLine" :style="{width: '100%', height: '300px'}"></div>
    </el-card>
  </div>
</template>
<script>
import params from "@/components/goods/params";

export default {
  data () {
    return {
      msg: 'Welcome to Your Vue.js App',
      query:{
        start_date:'',
        end_date:'',
        xtype:'',
      },
      options: [{
        value: '1',
        label: '今天'
      }, {
        value: '2',
        label: '本周'
      }, {
        value: '3',
        label: '本月'
      }, {
        value: '4',
        label: '本季度'
      }, {
        value: '5',
        label: '本年'
      }],
      Rangedate: '1',
      draw1Price:0,
      drawprice:0,
      customdate:''


    }
  },
  mounted(){
    // this.drawLine();
  },
  created() {
    this.gettoday()
    this.get()
    this.drawLine()
  },
  methods: {
    //获取今天时间
    gettoday(){
      var myDate = new Date();
      this.query.start_date=myDate.getFullYear() +'-'+ (myDate.getMonth()+1) +'-'+ myDate.getDate()
      myDate.setTime(myDate.getTime()+24*60*60*1000);
      this.query.end_date = myDate.getFullYear()+"-" + (myDate.getMonth()+1) + "-" + myDate.getDate();
      this.query.xtype='hours'
    },
    //获取付款金额
    async get(){
      const { data : res } = await this.$http.get('purchasecensus/getPaymentCount',{params:this.query})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.draw1data=res.data
      this.draw1Price = res.data.price
      console.log(this.drawdata)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        // title: { text: '销售金额' , align:'center' , textAlign : 'left'},
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.draw1data.datearr
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.draw1data.dataarr,
          type: 'line'
        }]
      });
    },
    //获取采购金额
    async drawLine(){
      const { data : res } = await this.$http.get('purchasecensus/getPriceCount',{params:this.query})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.drawdata=res.data
      this.drawprice=res.data.price
      console.log(this.drawdata)
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChartdrawLine'))
      // 绘制图表
      myChart.setOption({
        // title: { text: '在Vue中使用echarts' },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.drawdata.datearr
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: this.drawdata.dataarr,
          type: 'line'
        }]
      });
    },
    //自定义
    customdateChange(){
      this.Rangedate=''
      console.log(this.customdate)
      this.query.start_date=this.customdate[0]
      this.query.end_date =this.customdate[1]
      this.query.xtype='weeks'
      this.get()
      this.drawLine()
    },
    //
    formatDate (date) {
      var myYear = date.getFullYear();
      var myMonth = date.getMonth() + 1;
      var myDate = date.getDate();
      if(myMonth < 10) {
        myMonth = '0' + myMonth;
      }
      if(myDate < 10) {
        myDate = '0' + myDate;
      }
      return myYear + '-' + myMonth + '-' + myDate;
    },
    //选择时间范围
    RangedateChange(){
      this.customdate=''
      var myDate = new Date();
      var now_year = myDate.getFullYear();
      var now_month = myDate.getMonth();
      var now_date = myDate.getDate();
      var now_day = myDate.getDay();
      if(this.Rangedate==1){
        this.gettoday()
      }
      else if(this.Rangedate==2){
        var weekStart = new Date(now_year, now_month, now_date - now_day +1);
        var weekEnd = new Date(now_year, now_month, now_date + (7 - now_day)+1);
        this.query.start_date=this.formatDate(weekStart)
        this.query.end_date =this.formatDate(weekEnd)
        this.query.xtype='day'
        // return this.formatDate(weekEnd);
        // return this.formatDate(weekStart);
      }
      else if(this.Rangedate==3){
        var MonthStart = new Date(now_year, now_month, 1);
        // var MonthEnd = new Date((new Date(now_year, now_month + 1, 1)).getTime() - 1000 * 60 * 60* 24);
        var MonthEnd = new Date(now_year, now_month+1, 1);
        this.query.start_date = this.formatDate(MonthStart)
        this.query.end_date = this.formatDate(MonthEnd)
        this.query.xtype='weeks'
      }
      else if(this.Rangedate==4){
        var QuarterStartMonth;
        if (now_month < 3) {
          QuarterStartMonth = 0;
        }
        if (now_month > 2 && now_month < 6) {
          QuarterStartMonth = 3;
        }
        if (now_month > 5 && now_month < 9) {
          QuarterStartMonth = 6;
        }
        if (now_month > 8 && now_month < 11) {
          QuarterStartMonth = 9;
        }
        var QuarterStart = new Date(now_year, QuarterStartMonth, 1);
        var QuarterEnd = new Date((new Date(now_year, QuarterStartMonth + 3, 1)));
        // console.log(this.formatDate(QuarterStart),this.formatDate(QuarterEnd))
        this.query.start_date = this.formatDate(QuarterStart)
        this.query.end_date = this.formatDate(QuarterEnd)
        this.query.xtype='month'
      }
      else if(this.Rangedate==5){
        var YearStart = new Date(now_year, 0, 1);
        var YearEnd = new Date((new Date(now_year + 1, 0, 1)));
        console.log(this.formatDate(YearStart),this.formatDate(YearEnd))
        this.query.start_date = this.formatDate(YearStart)
        this.query.end_date = this.formatDate(YearEnd)
        this.query.xtype='month'
      }
      this.get()
      this.drawLine()

    }
  }


}
</script>
<style lang="less" scoped>
.Top_date{
  width: 100%;
  //height: 100px;
  box-sizing: border-box;
  background: #ffffff;
  margin-bottom: 20px;
}
.Top_title{
  position: relative;
  height: 42px;
  line-height: 42px;
  padding: 0 15px;
  border-bottom: 1px solid #f6f6f6;
  color: #333;
  border-radius: 2px 2px 0 0;
  font-size: 14px;
}
.el-select{
  margin-right: 30px;
}
.Top_conter{
  display: flex;
  padding: 20px;
}
.title{
  width: 100%;
  font-size: 24px;
  text-align: center;
}
.Top_Total{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.Top_TotalL,.Top_TotalR{
  width: 49%;
  box-sizing: border-box;
  background: #ffffff;
  //padding: 20px;


}
.Top_TotalRLi,.Top_TotalLLi{
  font-size: 36px;
  color: #666;
  line-height: 36px;
  padding: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
</style>
